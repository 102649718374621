@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~design-system-old/build/css/design-system-old.css";
@import "~design-system/build/css/design-system.css";
@import "theme/colors.css";
@import "theme/defaultTheme.css";
@import "theme/wds.css";
@import "theme/pageplugDesign.css";
:root {
  --secondary: "#74BFD4";
  --primary: #27b7b7;
  --bright: #fff;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF !important;
}
body .taro-img__mode-aspectfit {
  vertical-align: top;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .ant-pro-sider-menu .anticon {
  font-size: 19px!important;
}
body .ant-layout.ant-layout-has-sider {
  z-index: 1;
}
body .ant-pro-sider-light {
  z-index: 1;
}
body .ant-pro-basicLayout .ant-layout-header.ant-pro-header-light {
  z-index: 1!important;
}
body .rc-tree-select-tree {
  border: none;
}
body .rc-tree-select-dropdown {
  border: none;
}
body .rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher,
body .rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox {
  background-image: none!important;
}
body .rc-tree-select-single:not(.rc-tree-select-customize-input) .rc-tree-select-selector {
  padding: 0;
}
body .bp3-portal {
  z-index: 1001;
}
body .ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected,
body .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected,
body .ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: rgba(0, 0, 0, 0.8) !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
}
body.dragging * {
  cursor: grabbing !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
  outline: none !important;
}
div.bp3-select-popover .bp3-popover-content {
  padding: 0;
}
span.bp3-popover-target {
  display: flex;
  width: 100%;
}
span.bp3-popover-target > * {
  flex-grow: 1;
}
div.bp3-popover-arrow {
  display: none;
}
.bp3-button.bp3-loading {
  cursor: default !important;
}
.display-none {
  display: none;
}
.Toastify__toast {
  border-radius: 4px !important;
  box-shadow: none !important;
}
.Toastify__progress-bar--default {
  background: #03b365 !important;
}
.t--editor-appname-menu-portal {
  z-index: 9 !important;
}
.bp3-popover .bp3-input {
  outline: 0;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3 !important;
}
.rotate[data-v-075ccfd1] {
  -webkit-animation: rotating 0s linear infinite;
  animation: rotating 0s linear infinite;
}
@keyframes skill {
  0% {
    transform: rotate(0deg);
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 10px var(--primary), inset 0 0 20px var(--primary);
  }
  100% {
    transform: rotate(720deg);
    box-shadow: none;
  }
}
@keyframes skill_02 {
  0% {
    transform: rotate(0deg);
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 10px var(--secondary), inset 0 0 20px var(--secondary);
  }
  100% {
    transform: rotate(720deg);
    box-shadow: none;
  }
}
@keyframes float {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float2 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float3 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float4 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float5 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-60%);
  }
  100% {
    transform: translateY(0%);
  }
}
.bell {
  fill: #fff;
  position: absolute;
  top: 170px;
  left: 175px;
  -webkit-animation: ringing 2.5s ease infinite;
  animation: ringing 2.5s ease infinite;
}
.line {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
}
.line-1,
.line-2,
.line-3 {
  position: absolute;
  top: 170px;
  left: 230px;
}
.line-4,
.line-5,
.line-6 {
  position: absolute;
  top: 170px;
  right: 230px;
}
.line-1 {
  -webkit-animation: line-1 2.5s ease-out infinite both;
  animation: line-1 2.5s ease-out infinite both;
}
.line-2 {
  -webkit-animation: line-2 2.5s ease-out infinite both;
  animation: line-2 2.5s ease-out infinite both;
}
.line-3 {
  -webkit-animation: line-3 2.5s ease-out infinite both;
  animation: line-3 2.5s ease-out infinite both;
}
.line-4 {
  -webkit-animation: line-4 2.5s ease-out infinite both;
  animation: line-4 2.5s ease-out infinite both;
}
.line-5 {
  -webkit-animation: line-5 2.5s ease-out infinite both;
  animation: line-5 2.5s ease-out infinite both;
}
.line-6 {
  -webkit-animation: line-6 2.5s ease-out infinite both;
  animation: line-6 2.5s ease-out infinite both;
}
@-webkit-keyframes line-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%,
  100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
    transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%,
  100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
    transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-2 {
  0%,
  19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%,
  100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
    transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-2 {
  0%,
  19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%,
  100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
    transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-3 {
  0%,
  39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
    transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-3 {
  0%,
  39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
    transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%,
  100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
    transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%,
  100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
    transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-5 {
  0%,
  19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%,
  100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
    transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-5 {
  0%,
  19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%,
  100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
    transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-6 {
  0%,
  39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
    transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-6 {
  0%,
  39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
    transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes ringing {
  0%,
  50%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  5%,
  10%,
  15%,
  20%,
  30%,
  35%,
  40% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  45% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  7.5%,
  12.5%,
  17.5%,
  22.5%,
  27.5%,
  32.5%,
  37.5%,
  42.5% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  47.5% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
}
@keyframes ringing {
  0%,
  50%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  5%,
  10%,
  15%,
  20%,
  30%,
  35%,
  40% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  45% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  7.5%,
  12.5%,
  17.5%,
  22.5%,
  27.5%,
  32.5%,
  37.5%,
  42.5% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  47.5% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
}
.bp-transparent {
  --wds-screen-color-text: #fafafa;
  background-color: var(--wds-screen-color-bg) !important;
  color: var(--wds-screen-color-text) !important;
}
.bp-transparent > * .bp3-input-group {
  background-color: var(--wds-screen-color-bg) !important;
}
.bp-transparent > * .bp3-input-group input,
.bp-transparent > * .bp3-input-group textarea,
.bp-transparent > * .bp3-input-group button {
  background-color: var(--wds-screen-color-bg) !important;
  color: var(--wds-screen-color-text) !important;
}
.bp-transparent > * .ant-card {
  background-color: var(--wds-screen-color-bg) !important;
}
.bp-transparent > * .ant-card-head {
  color: var(--wds-screen-color-text) !important;
}
.bp-transparent div[data-testid="switchgroup-container"] {
  color: var(--wds-screen-color-text);
}
.bp3-popover .bp3-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}
.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}
.layout-control.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}
.bp3-popover.none-shadow-popover {
  box-shadow: none;
  border: 0px;
}
.bp3-datepicker {
  border-radius: 0 !important;
}
/* rich text editor styles */
.tox-tinymce {
  border-color: #E7E7E7 !important;
}
.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type),
.tox .tox-statusbar {
  border-color: #E7E7E7 !important;
}
.tox .tox-toolbar__primary {
  background: white !important;
  border-bottom: 1px solid #E7E7E7 !important;
}
/* making both the Modal layer and the Dropdown layer */
.bp3-modal-widget,
.appsmith_widget_0 > .bp3-portal {
  z-index: 2 !important;
}
/* Portals on the Modal widget */
.bp3-modal-widget .bp3-portal {
  z-index: 21 !important;
}
.bp3-overlay-backdrop {
  background-color: rgba(16, 22, 26, 0.7) !important;
}
.bp3-popover,
.bp3-popover2,
.bp3-popover2 .bp3-popover2-content,
.bp3-popover .bp3-popover-content {
  border-radius: 3px !important;
}
.bp3-dialog {
  border-radius: 8px !important;
}
.bp3-overlay .bp3-overlay-content {
  border-radius: 5px !important;
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  font-family: auto!important;
}
.full-height-form {
  height: 100%;
}
.full-height-form > form {
  height: 100%;
}
.designable-drawer .dn-aux-helpers button {
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 3px !important;
  height: 20px !important;
  color: var(--dn-aux-selector-btn-color) !important;
  background: var(--dn-aux-selector-btn-bg-color) !important;
  border-color: var(--dn-aux-selector-btn-border-color) !important;
}
.designable-drawer .dn-main-panel.root {
  height: auto;
  top: 57px;
  bottom: 49px;
}
.designable-drawer .dn-light {
  --dn-brand-color: var(--ads-color-brand);
  --dn-brand-hovering: var(--ads-color-brand-hover);
  --dn-brand-dragging: rgba(0, 0, 0, 0.26);
  --dn-brand-dropping: rgba(0, 0, 0, 0.34);
  --dn-brand-moving: rgba(0, 0, 0, 0.5);
}
/* react-sortable-tree styles */
.pageplug-rst .rstcustom__expandButton {
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+") no-repeat center;
}
.pageplug-rst .rstcustom__collapseButton {
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==") no-repeat center;
}
.pageplug-rst .rstcustom__rowContents {
  box-shadow: none;
  cursor: grab;
  border: 1px solid var(--ads-color-brand);
  border-radius: 4px;
  background: var(--ads-color-background-secondary);
}
.pageplug-rst .rstcustom__rowWrapper {
  padding: 10px 10px 10px 0;
}
.pageplug-rst .rstcustom__rowWrapper .rstcustom__rowLandingPad::before {
  border: 3px dashed var(--ads-color-brand);
  background: var(--ads-color-background-secondary);
  border-radius: 4px;
}
.pageplug-rst .rstcustom__rowWrapper .rstcustom__rowCancelPad::before {
  border: 3px dashed #ff4d4f;
  background: #ffccc7;
}
.pageplug-rst .rst__placeholder::before {
  z-index: 0;
}
.pageplug-rst .rst__lineHalfHorizontalRight::before,
.pageplug-rst .rst__lineFullVertical::after,
.pageplug-rst .rst__lineHalfVerticalTop::after,
.pageplug-rst .rst__lineHalfVerticalBottom::after,
.pageplug-rst .rstcustom__lineChildren::after {
  background-color: #c7c7c7;
}
.pageplug-rst .rstcustom__rowWrapper:hover {
  opacity: 1;
}
.pageplug-rst .rstcustom__rowWrapper:hover .rstcustom__rowContents {
  background: #fff;
  box-shadow: 0 0 0 4px var(--ads-color-background-secondary);
}
.pageplug-rst .icon-select-container {
  display: inline-block;
}
.pageplug-rst .rst__placeholder {
  max-width: unset;
}
.pageplug-rst .rst__placeholder::before {
  left: 20px;
  right: 20px;
}
.pageplug-rst .rst__placeholder.rst__placeholderLandingPad::before {
  border: 3px dashed var(--ads-color-brand);
  background: var(--ads-color-background-secondary);
  border-radius: 4px;
}
.pageplug-rst .rst__placeholder.rst__placeholderCancelPad::before {
  border: 3px dashed #ff4d4f;
  background: #ffccc7;
}
.pageplug-rst .rstcustom__toolbarButton {
  margin-right: 5px;
}
::selection {
  color: var(--ads-color-brand-text) !important;
  background: var(--ads-color-brand) !important;
}
.BMap_cpyCtrl {
  display: none!important;
}
.anchorBL {
  display: none!important;
}
.expandedWrap > td {
  background-color: #fff !important;
}
.jsonViewWrap {
  min-height: 28px;
  padding: 8px 30px 8px 8px;
  background-color: #ffffff;
  position: relative;
  border: 1px solid #b3b3b3;
}
.auditTable .ant-table-thead {
  height: 40px;
  position: sticky;
  top: 48px;
  z-index: 100;
}
.auditTableRow {
  cursor: pointer;
}
.auditTableRow > td {
  padding: 8px !important;
}
.auditForm .ant-form-item {
  margin-bottom: 8px !important;
}
.ant-form-item-control-input > .ant-form-item-control-input-content > div.ant-input-number:hover,
.ant-form-item-control-input > .ant-form-item-control-input-content > div.ant-input-number:focus,
.ant-form-item-control-input > .ant-form-item-control-input-content > div.ant-input-number:focus-within {
  border-color: #4096ff;
}
div.rc-select-show-arrow.rc-select-multiple .rc-select-selector {
  height: auto;
}
.rc-select-selection-overflow-item span.ads-v2-text {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  /* 兼容性设置，用于处理长单词或URL的换行 */
}
