@import "./tailwind.css";
@import "./tailwind-preflight.css";

@font-face{
	font-family: digital;
	src:url('../font/digital.ttf'); /* IE9+,可以是具体的实际链接 */
}
@font-face{
	font-family: clock;
	src:url('../font/clock.ttf'); /* IE9+,可以是具体的实际链接 */
}
@font-face{
	font-family: douyu;
	src:url('../font/font_douyu.otf'); /* IE9+,可以是具体的实际链接 */
}
@font-face{
	font-family: guan;
	src:url('../font/font_guanblack.ttf'); /* IE9+,可以是具体的实际链接 */
}
@font-face{
	font-family: pblack;
	src:url('../font/font_printblack.otf'); /* IE9+,可以是具体的实际链接 */
}
@font-face{
	font-family: tblack;
	src:url('../font/font_titleblack.ttf'); /* IE9+,可以是具体的实际链接 */
}

/**
* ---------------------------------------------------------------------------------------------------
* general
* ---------------------------------------------------------------------------------------------------
*/
body,
html {
  @apply w-full h-full overflow-x-hidden;
}

/**
* ---------------------------------------------------------------------------------------------------
* blueprint specific css overrides
* ---------------------------------------------------------------------------------------------------
*/
.bp3-panel-stack-view {
  background: none;
}

/**
* ---------------------------------------------------------------------------------------------------
* custom css
* ---------------------------------------------------------------------------------------------------
*/

* {
  scrollbar-width: thin;
  scrollbar-color: var(--ads-v2-color-bg-emphasis) transparent;
}

/* For some reason, margin-left: -160px; is being added to the react-toastify stylesheet on the .Toastify__toast-container--top-center
and .Toastify__toast-container--bottom-center classes, which messes with the placement of toast. This line resets that. */
.Toastify__toast-container--top-center,
.Toastify__toast-container--bottom-center {
  margin-left: 0;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--ads-v2-color-bg-emphasis);
  border-radius: 36px;
  opacity: 0.5;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

input::placeholder,
textarea::placeholder {
  color: var(--ads-v2-color-fg-subtle);
}

/* login page */
.login-page-bg {
  background-image: url("../images/login-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.login-bg {
  background-color: transparent !important;
}

.login-img {
  height: 90px;
  width: 261px;
  object-fit: contain;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fs-16,
.ads-v2-link__text {
  font-size: 16px !important;
  color: #13c2c2 !important;
}

.myfont {
  font-size: 16px;
  color: #000000e5;
}

.ads-v2-input__input-section-input:focus:enabled:not(:read-only) {
  --input-color-border: #13c2c2 !important;
}

.ads-v2-input__input-section-input:focus {
  background-color: var(--ads-v2-color-bg) !important;
}

.sc-easbae > .ads-v2-button[data-disabled="true"] > .ads-v2-button__content {
  background-color: #00000042;
  border-color: #00000042;
}
/* 图标hack处理 */
.bp3-form-content {
  position: relative;
}

.icon-position {
  position: absolute;
  top: 15px;
  left: 7px;
}

/* 下划线去掉 */
.a_link:hover {
  text-decoration: none !important;
}
/* 按钮禁用状态 */
.ads-v2-button:disabled > .ads-v2-button__content {
  background-color: #aabdba;
  border: 1px solid #aabdba;
}
.t--connection.ads-v2-button:disabled > .ads-v2-button__content {
  background-color: transparent;
  border: transparent;
}
/* 消息提示 */
.my-msg.ant-message-notice-error > .ant-message-notice-content {
  border: 1px solid #ff5449 !important;
  background-color: #fff0ed !important;
}

.my-msg.ant-message-notice-success > .ant-message-notice-content {
  border: 1px solid #34c724 !important;
  background-color: #e3f9e9 !important;
}
/* 邀请页调整 */
.t--invite-user-btn {
  width: 55px;
}

.t--property-control-wrapper .ads-v2-segmented-control {
  width: 100%;
}

.t--change-theme-btn.temp-class:hover {
  background-color: #13c2c2;
  border: 1px solid #13c2c2;
}

.pp-height .ads-v2-input__input-section-input {
  height: 48px;
}

button.ads-v2-button.pp-height {
  height: 48px;
}

.pp-font span.ads-v2-button__content-children {
  height: 28px;
  width: 50px;
  font-family: Source Han Sans CN VF;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.5em;
}

.ant-menu-dark.pp-menu
  > .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.09);
}

.pp-menu.ant-menu-dark
  > .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-selected {
  background-color: rgba(0, 0, 0, 0.09);
}

.ant-menu-dark
  > .ant-menu-vertical
  > .ant-menu-submenu-open.ant-menu-submenu-active {
  background-color: transparent;
}

.ant-menu-dark
  > .ant-menu-vertical
  > .ant-menu-submenu-open.ant-menu-submenu-active
  > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.8);
}

.ant-menu-dark
  .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.8);
}

div.ant-picker-dropdown.ant-picker-dropdown-placement-topLeft {
  height: 200px;
}

.form-group .ant-form-item .ant-form-item-extra #root__description {
  display: none;
}

.ant-form-item .ant-form-item-row .ant-form-item-label>label {
  font-weight: 400;
  font-size: 14px;
}

.rjsf {
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 15px;
}

div.ant-col.ant-col-24.ant-form-item-label> label[for="root__title"]{
  font-size: 20px;
  font-weight: 700;
}

.form-group > .ant-form-item {
  margin-bottom: 5px;
}

div.ant-col.ant-col-24.ant-form-item-label {
  padding: 0;
}

.ads-v2-input__input-section-input[name="verificationCode"] {
  height: 48px;
}

div.react-datepicker-wrapper {
  display: block;
}
